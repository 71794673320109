import { createContext, useContext, useState } from 'react';

interface MicrositeContextLoadingImages {
  loadingImages: boolean;
  setLoadingImages: (value: boolean) => void;
}

const MicrositeLoadingImageContext = createContext({});

interface Props {
  children?: JSX.Element | JSX.Element[];
}

export function MicrositeLoadingImageContextProvider({
  children,
}: Props): JSX.Element {
  const [loadingImages, setLoadingImages] = useState(false);

  return (
    <MicrositeLoadingImageContext.Provider
      value={{
        loadingImages,
        setLoadingImages,
      }}
    >
      {children}
    </MicrositeLoadingImageContext.Provider>
  );
}

export default function useMicrositeLoadingImageContext(): MicrositeContextLoadingImages {
  const context = useContext(
    MicrositeLoadingImageContext
  ) as MicrositeContextLoadingImages;
  return context;
}
